import React from 'react';
import './ServiceModal.css';
import { AiOutlineClose } from "react-icons/ai";

const ServiceModal = ({ title, content, isOpen, isClose, children }) => {
  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      isClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-wrapper">
        <AiOutlineClose onClick={isClose} className='modal-close-button'/>
        <h1>{title}</h1>
        <p>{content}</p>
        <div className="modal-children">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
