import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import "./ProjectCards.css"; 

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text className="card-text">
          {props.description}
        </Card.Text>
        {props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            className="fixed-button"
          >
            <CgWebsite /> &nbsp;
            {"Visitar"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
