import React from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import { ServiceList } from "./fragments/service-list";
import './Services.css'

function Services() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px", width: "100%", height: "100%" }} className="project-card" xs={1}>
            <h1 className="project-heading" style={{ paddingBottom: "40px" }}>
              <strong className="purple">Serviços</strong>
            </h1>
            <ServiceList />
        </Row>
      </Container>
    </Container>
  );
}

export default Services;
