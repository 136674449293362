import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import whatsGenerator from '../../Assets/Projects/whatsgenerator.png'
import canoasShopping from '../../Assets/Projects/canoasShop.png'
import vipLeiloes from '../../Assets/Projects/vipLeiloes.png'
import brum from '../../Assets/Projects/brumBalancas.png'

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Nossos trabalhos <strong className="purple">recentes </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={whatsGenerator}
              isBlog={false}
              title="Gerador de links para o whatsapp"
              description="Esta página permite que os usuários insiram um número de telefone e, com um clique, gerem um link direto para iniciar uma conversa no WhatsApp com esse número. É uma ferramenta prática para facilitar a comunicação via WhatsApp, eliminando a necessidade de salvar o número nos contatos antes de iniciar a conversa. Ideal para negócios que desejam agilizar o atendimento ao cliente e para usuários que frequentemente precisam compartilhar seu número para contato rápido."
              demoLink="https://umrodrigues.github.io/gerar-link-whatsapp/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={canoasShopping}
              title="Canoas Guitar Shop -  Eccomerce"
              description="A Canoas Guitar Shop (CGS) é um eccomerce com sua sede em Canoas-RS, a loja é hospedada em uma platarorma, porém todo layout e configurações foram feitas pelo time da Lunaris Tech. Além da parte técnica neste cliente foi realizado toda assessoria de eccomerce com orientação das melhores formas de pagamento, envio, ferramentas de marketing e tudo que engloba a venda na internet."
              demoLink="https://canoasguitarshop.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vipLeiloes}
              title="Blog -  Vip Leilões"
              description="A Vip Leilões é uma empresa referência em leilões, neste cliente foi realizado manutenções no blog, o mesmo sendo Wordpress, pontos de seo, tagueamento e outras ferramentas de busca estavam sofrendo problemas. Onde o time da Lunaris Tech realizous as correções."
              demoLink="https://blog.vipleiloes.com.br/"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={brum}
              title="Brum Balanças"
              description="Com sede em Canoas – RS, a Brum Balanças atua desde 1987 no segmento de Vendas, Assistência Técnica, Calibração e Locação de Balanças e Sistemas de Pesagem. Foi realizado um projeto para a empresa, onde a equipe Lunaris Tech realizou ajustes no painel administrativo, foram corrigidos erros de certificado SSL e atualização da versão do PHP, melhorando a segurança e o desempenho do sistema."
              demoLink="https://brumbalancas.com/"              
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
