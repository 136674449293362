import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          A Lunaris Tech é uma empresa inovadora dedicada a fornecer soluções tecnológicas profissionais e ágeis. Com uma abordagem centrada no cliente, desenvolvemos e implementamos produtos e serviços que atendem às necessidades específicas de nossos parceiros, garantindo eficiência e resultados de alta qualidade. Nosso compromisso com a inovação e a excelência nos permite entregar soluções que impulsionam o crescimento e a transformação digital de nossos clientes.
          </p>
          <p style={{ textAlign: "justify" }}>
          Nossa equipe é composta por especialistas altamente qualificados e apaixonados por tecnologia, o que nos permite oferecer uma ampla gama de serviços, desde desenvolvimento de software personalizado até consultoria em TI e suporte técnico. Na Lunaris Tech, acreditamos que a combinação de conhecimento técnico, criatividade e agilidade é a chave para resolver os desafios mais complexos e proporcionar uma experiência excepcional para nossos clientes.

          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
