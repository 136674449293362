import React, { useState } from 'react';
import ServiceModal from './service-modal';
import { Col, Row } from "react-bootstrap";
import { servicesMock } from '../__mocks__/services_mock';
import './ServiceModal.css';

export const ServiceList = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentService, setCurrentService] = useState(null);

  const openModal = (service) => {
    setCurrentService(service);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentService(null);
  };

  return (
    <div className="App">
      <Row style={{ justifyContent: "center", paddingBottom: "40px" }}>
        {servicesMock.map((service, index) => (
          <Col key={index} xs={5} md={3} className="tech-icons" style={{ width: "100%", height: "auto"}}>
            <div className="service-title">
              <h1>{service.title}</h1>
              <p>{service.content}</p>
              <button onClick={() => openModal(service)}>Saiba mais</button>
            </div>
          </Col>
        ))}
      </Row>

      {currentService && (
        <ServiceModal
          title={currentService.title}
          content={currentService.content}
          isOpen={isModalOpen}
          isClose={closeModal}
        >
          <div className="modal-children">
            <p>{currentService.children}</p>
          </div>
        </ServiceModal>
      )}
    </div>
  );
};
