export const servicesMock = [
    {
        title: "Landing pages",
        content: "Realizamos a criação da landing page para o seu negócio",
        children: "teste",
    },
    {
        title: "E-commerce",
        content: "Criamos e hospedamos a sua loja virtual, consultações e vendas",
        children: "teste",
    },
    {
        title: "Sistemas Personalizados",
        content: "Automações empresarias para o seu negócio",
        children: "teste",
    },
    ]